import { format, parse } from 'date-fns';
import { FileInfoSchema } from '@server/file/file.models';

export const transferFormData = <T extends Record<string, any>>(obj: T): FormData => {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (Array.isArray(value)) {
            value.forEach((val, index) => {
                appendValueToFormData(formData, `${key}[${index}]`, val);
            });
        } else {
            appendValueToFormData(formData, key, value);
        }
    });
    return formData;
};

const appendValueToFormData = (formData: FormData, key: string, value: any) => {
    if (value === null || value === undefined) {
        return;
    }
    if (value instanceof File) {
        formData.append(key, value, value.name);
    } else if (value instanceof Object && FileInfoSchema.safeParse(value).success) {
        Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== null && value[subKey] !== undefined) {
                if (value[subKey] instanceof File) {
                    formData.append(`${key}.${subKey}`, value[subKey], value[subKey].name);
                } else {
                    formData.append(`${key}.${subKey}`, String(value[subKey]));
                }
            }
        });
    } else if (value instanceof Object) {
        Object.keys(value).forEach((subKey) => {
            appendValueToFormData(formData, `${key}.${subKey}`, value[subKey]);
        });
    } else {
        formData.append(key, String(value));
    }
};

export const dateToString = (date: Date | null) => {
    if (date) {
        const formattedDate = format(date, 'yyyy-MM-dd');
        return formattedDate;
    }
    return '';
};

export const stringToDate = (dateString: string): Date | null => {
    if (dateString) {
        const date = parse(dateString, 'yyyy-MM-dd', new Date());
        return date;
    }
    return null;
};

export function formatDateTime(dateTimeString: string) {
    if (!dateTimeString) return '';

    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatDate(dateString: string, format: string = '-') {
    if (!dateString) return '';

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${format}${month}${format}${day}`;
}

export const consoleFormData = (formData: FormData) => {
    for (const key of formData.keys()) {
        console.log(key, ':', formData.get(key));
    }
};

export const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return [hrs.toString().padStart(2, '0'), mins.toString().padStart(2, '0'), secs.toString().padStart(2, '0')].join(
        ':',
    );
};
